var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"lT7IoDI-PYqaI7b3cSI9Y"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import getConfig from 'next/config';
import * as Sentry from '@sentry/nextjs';

const { publicRuntimeConfig } = getConfig();

Sentry.init({
  dsn: publicRuntimeConfig.sentryDsn,
  environment: publicRuntimeConfig.sentryEnvironment,
  beforeBreadcrumb(breadcrumb) {
    if (breadcrumb.category === 'fetch' && breadcrumb?.data?.url) {
      const { url } = breadcrumb.data;
      if (
        url.includes('segment') ||
        url.includes('googletagmanager') ||
        url.includes('launchdarkly')
      ) {
        // Do not add these breadcrumbs
        return null;
      }
    }

    return breadcrumb;
  },
  beforeSend(event) {
    // If Sentry is passed something other than an error, it adds this __serialized__ object to
    // extra. We can use these to filter out bad errors coming from third-party code (GoogleAnalytics)
    // and ResizeLoop errors caused by password manager extensions resizing forms.
    // eslint-disable-next-line no-underscore-dangle
    if (event?.extra?.__serialized__ === undefined) {
      return event;
    }
    return null;
  },
});
